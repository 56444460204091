import axios from 'axios'
export default {
  reset_password_mail(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/${id}/reset_password_mail`)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  bad(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/${id}/bad`)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  notbad(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`user/${id}/notbad`)
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getExportUrl(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('user/export/excel/signedurl', {
          params: params
        })
        .then(res => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}