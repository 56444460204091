<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.stone_model[modelName].modelName"
      :label="$store.state.stone_model[modelName].label"
      :fields="$store.state.stone_model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :showExpand="false"
      :itemsPerPage="100"
      :expandable="false"
      :customTableActions="_customTableActions"
      @send-reset-password="$_onSendResetPassword($event)"
      @add-to-is-bad="$_onAddToIsBad($event)"
      v-model="modelDatas"
      :selectable="_selectable"
      :updateHideFields="updateHideFields"
      :selected="selected"
      @update:selected="$_onUpdateSelectd($event)"
      :titleBarCustomBtns="_titleBarCustomBtns"
      :creatable="false"
      :filterTabs="_filterTabs"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
      @read="$_onRead($event)"
    ></WsCrud>
  </WsMain>
</template>

<script>
import S_API_User from "@/__vue2stone_cms/service/api/v1/user";
export default {
  metaInfo() {
    return {
      title: this.$t("會員"),
    };
  },

  data() {
    return {
      selected: [],
      modelDatas: [],
      modelName: "user",
      updateHideFields: ["password"],
      showFields: ["uuid", "customer_id", "name", "email", "acumatica_id"],
    };
  },

  computed: {
    _filterTabs() {
      let _filterTabs = [];
      if (
        this.$config.wsmodule.user.is_bad ||
        this.$config.wsmodule.user.subscribe
      ) {
        _filterTabs = [
          ..._filterTabs,
          {
            text: this.$t("全部"),
            value: "all",
            params: {},
          },
        ];
      }
      if (this.$config.wsmodule.user.is_bad) {
        _filterTabs = [
          ..._filterTabs,
          {
            text: this.$t("黑名單"),
            value: "is_bad",
            params: {
              is_bad: 1,
            },
          },
        ];
      }
      if (this.$config.wsmodule.user.subscribe) {
        _filterTabs = [
          ..._filterTabs,
          {
            text: this.$t("未訂閱"),
            value: "unsubscribe",
            params: {
              subscribe_status: "unsubscribe",
            },
          },
          {
            text: this.$t("訂閱中"),
            value: "subscribing",
            params: {
              subscribe_status: "subscribing",
            },
          },
          {
            text: this.$t("訂閱逾期"),
            value: "subscribe-expired",
            params: {
              subscribe_status: "subscribe-expired",
            },
          },
        ];
      }
      return _filterTabs;
    },
    _selectable() {
      if (this.$config.wsmodule.user.export) {
        return true;
      } else {
        return false;
      }
    },
    _customTableActions() {
      const _customTableActions = [];
      if (this.$config.wsmodule.user.reset_password_mail) {
        _customTableActions.push({
          icon: "icon-ws-officialweb-send",
          emit: "send-reset-password",
          tooltip: this.$t("寄送修改密碼信"),
        });
      }
      if (this.$config.wsmodule.user.is_bad) {
        const removeBlacklist = this.$t("移除黑名單");
        const addBlackList = this.$t("加入黑名單");
        _customTableActions.push({
          emit: "add-to-is-bad",
          getIcon(item) {
            if (item.is_bad) {
              return "icon-md-do-not-disturb-off";
            } else {
              return "icon-md-do-not-disturb-on";
            }
          },
          getTooltip(item) {
            if (item.is_bad) {
              // 移除黑名單
              return removeBlacklist;
            } else {
              // 加入黑名單
              return addBlackList;
            }
          },
        });
      }
      _customTableActions.push({
        icon: "icon-ws-outline-eye-open",
        emit: "read",
        tooltip: this.$t("閱讀"),
      });
      return _customTableActions;
    },
    _selectedUserIds() {
      const _selectedUserIds = [];
      this.selected.forEach((selectedItem) => {
        _selectedUserIds.push(selectedItem.id);
      });
      return _selectedUserIds;
    },
    _exportSelectedDisabled() {
      if (this.selected.length) {
        return false;
      } else {
        return true;
      }
    },
    _titleBarCustomBtns() {
      const _titleBarCustomBtns = [];
      if (this.$config.wsmodule.user.export) {
        _titleBarCustomBtns.push(
          ...[
            {
              label: this.$t("匯出所有會員資訊"),
              event: "export-all",
            },
            {
              label: this.$t("匯出所選會員資訊"),
              event: "export-select",
              disabled: this._exportSelectedDisabled,
            },
          ]
        );
      }
      return _titleBarCustomBtns;
    },
  },

  methods: {
    $_titleBarCustomBtnClick($event) {
      if ($event == "export-all") {
        this.$_onExportAll();
      } else if ($event == "export-select") {
        this.$_onExportSelect();
      }
    },
    async $_onExportAll() {
      const res = await S_API_User.getExportUrl();
      window.open(res.data, "_blank");
    },
    async $_onExportSelect() {
      const res = await S_API_User.getExportUrl({
        ids: this._selectedUserIds.join(),
      });
      window.open(res.data, "_blank");
    },
    $_onUpdateSelectd($event) {
      this.selected = $event;
    },
    async $_onSendResetPassword($event) {
      await S_API_User.reset_password_mail($event.item.id);
      this.$store.dispatch("app/addSnack", `${this.$t("信件已寄出")}!`);
    },
    async $_onAddToIsBad($event) {
      if ($event.item.is_bad) {
        await S_API_User.notbad($event.item.id);
        this.modelDatas[$event.itemIndex].is_bad = 0;
        this.$store.dispatch("app/addSnack", `${this.$t("移除黑名單")}!`);
      } else {
        await S_API_User.bad($event.item.id);
        this.modelDatas[$event.itemIndex].is_bad = 1;
        this.$store.dispatch("app/addSnack", `${this.$t("加入黑名單")}!`);
      }
    },
    $_onRead($event) {
      this.$router.push(`/user/${$event.item.id}`);
    },
  },
};
</script>